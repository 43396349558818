@let blok = block();

<ng-template #cardContent>
    <span class="flex flex-row lg:flex-col lg:h-auto lg:min-h-44">
        @if (blok.asset.filename) {
            <img
                class="w-1/4 max-w-28 lg:max-w-full lg:w-full lg:h-56 object-cover"
                sizes="auto"
                height="288"
                width="288"
                alt="{{ blok.asset.alt }}"
                [ngSrc]="sanitizedResourceLink()"
                [title]="blok.asset.title"
                [priority]="blok.priority"
            />
        }

        <span class="flex flex-row lg:flex-col justify-between items-center gap-3 px-5 py-5 lg:px-10 w-full lg:w-full">
            <span class="flex flex-col">
                @if (blok.descriptionText) {
                    <h2 class="text-left lg:text-center w-full break-words hyphens-auto text-sm lg:text-lg font-body">
                        {{ blok.descriptionText }}
                    </h2>
                }
                @if (blok.subDescriptionText) {
                    <p class="text-left lg:text-center w-full break-words hyphens-auto text-xs lg:text-base font-body">
                        {{ blok.subDescriptionText }}
                    </p>
                }
            </span>

            @if (blok.buttonText) {
                <s1-button
                    [onlySpan]="true"
                    [block]="{
            text: blok.buttonText,
            color: blok.buttonColor,
            size: blok.buttonSize,
            link: blok.link
          }"
                    class="!hidden lg:!block"
                />
            }

            <img
                class="inline-block bg-accent-dark rounded-xl min-w-10 lg:hidden"
                src="/icons/arrow-right.svg"
                width="46"
                height="44"
                alt="Call"
            />
        </span>
    </span>
</ng-template>

@if (blok.promoted) {
    <p class="bg-primary text-white text-sm font-body text-center px-3 py-1 lg:px-3 lg:py-3">
        {{ blok.promotedTitle }}
    </p>
}

@switch (blok.link.linktype) {
    @case ('story') {
        <a
            class="block"
            [routerLink]="blok.link.cached_url | sbRouterLink | cachedUrlResolver"
            [fragment]="blok.link.anchor"
            [target]="blok.link.target"
        >
            <ng-container [ngTemplateOutlet]="cardContent" />
        </a>
    }
    @case ('url') {
        <a
            class="block"
            [href]="sanitizedLink() | desanitizeSlash"
            [target]="blok.link.target"
            [rel]="blok.link.target === '_blank' ? 'noopener noreferrer' : undefined"
        >
            <ng-container [ngTemplateOutlet]="cardContent" />
        </a>
    }
    @case ('email') {
        <a class="block" [href]="'mailto:' + sanitizedLink() | desanitizeSlash">
            <ng-container [ngTemplateOutlet]="cardContent" />
        </a>
    }
    @case ('asset') {
        <a
            class="block"
            [href]="sanitizedLink() | desanitizeSlash"
            [target]="blok.link.target"
        >
            <ng-container [ngTemplateOutlet]="cardContent" />
        </a>
    }
    @default {
        <span class="block">
            <ng-container [ngTemplateOutlet]="cardContent" />
        </span>
    }
}
