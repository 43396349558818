@let b = block();
@let sizeClass = b.size ? 's1-button--size-' + b.size : 's1-button--size-default';

@if (onlySpan()) {
    <span [ngClass]="sizeClass">
        {{ b.text }}
    </span>
} @else {
    @if (b.link.linktype) {
        @switch (b.link!.linktype) {
            @case ('story') {
                <a
                    [routerLink]="b.link.cached_url | sbRouterLink | cachedUrlResolver"
                    [fragment]="b.link.anchor"
                    [target]="b.link.target"
                    [ngClass]="sizeClass">
                    {{ b.text }}
                </a>
            }
            @case ('url') {
                <a
                    [href]="sanitizedLink() | desanitizeSlash"
                    [target]="b.link.target"
                    [rel]="b.link.target === '_blank' ? 'noopener noreferrer' : undefined"
                    [ngClass]="sizeClass">
                    {{ b.text }}
                </a>
            }
            @case ('email') {
                <a [href]="'mailto:' + sanitizedLink | desanitizeSlash"
                   [ngClass]="sizeClass">
                    {{ b.text }}
                </a>
            }
            @case ('asset') {
                <a
                    [href]="sanitizedLink() | desanitizeSlash"
                    [target]="b.link.target"
                    [ngClass]="[sizeClass, 'max-w-full']">
                    {{ b.text }}
                    <img class="inline-block ml-5"
                         ngSrc="/icons/download.svg"
                         width="18"
                         height="18"
                         alt="Call"
                    />
                </a>
            }
        }
    }
}
