import { Route } from '@angular/router';
import { routeChangeActivateGuard, routeChangeDeactivateGuard } from '@seven1/angular/gtm';
import redirectedRoutes from '../../public/data/redirectedRoutes.json';
import { navigationDeactivateGuard } from './routing';
import {
    layoutResolver,
    storyResolver
} from '@seven1/angular-storyblok-components/resolver';
import { environment } from '../environments/environment';

const redirects = redirectedRoutes.map(route => {
   return {
       ...route,
       path: route.path.startsWith(environment.baseUrl) ? route.path.slice(`${environment.baseUrl}/`.length, route.path.length) : route.path,
   }
});


export const appRoutes: Route[] = [
    ...(redirects as Route[]),
    {
        path: environment.cms.blogSlug,
        loadChildren: () => import('./pages/blog').then(children => children.blog_routes)
    },
    {
        path: ':categoryId/' + environment.cms.blogSlug,
        loadComponent: () => import('./pages/blog').then((page) => page.BlogCategoryPage),
        resolve: {
            story: storyResolver,
            layout: layoutResolver,
        },
        canActivate: [routeChangeActivateGuard],
        canDeactivate: [routeChangeDeactivateGuard],
    },
    {
        path: ':categoryId/' + environment.cms.blogSlug + '/:articleId',
        loadComponent: () => import('./pages/blog').then((page) => page.BlogArticlePage),
        resolve: {
            story: storyResolver,
            layout: layoutResolver,
        },
        canActivate: [routeChangeActivateGuard],
        canDeactivate: [routeChangeDeactivateGuard],
    },
    {
        path: '**',
        loadComponent: () => import('./cms/page').then(cmp => cmp.CmsPage),
        resolve: {
            story: storyResolver,
            layout: layoutResolver,
        },
        canActivate: [routeChangeActivateGuard],
        canDeactivate: [routeChangeDeactivateGuard, navigationDeactivateGuard],
    },
];
