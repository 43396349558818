import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { DesanitizeSlashPipe } from '@seven1/angular/utils';
import { IButtonBlock } from './button.model';
import { TailwindService } from '@seven1/angular-storyblok/tailwind';
import { CachedUrlResolverPipe, SbLinkHelper } from '@seven1/angular-storyblok/link';
import { RouterLinkPipe } from '../pipes';

@Component({
    selector: 's1-button',
    imports: [NgClass, DesanitizeSlashPipe, RouterLink, CachedUrlResolverPipe, NgOptimizedImage, RouterLinkPipe],
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class]': 'classes()',
    },
})
export class ButtonComponent {
    private _tailwind = inject(TailwindService);
    private _sanitizer = inject(DomSanitizer);

    block = input.required<IButtonBlock>();
    onlySpan = input<boolean>();

    classes = computed(() => {
        const block = this.block();
        return ['s1-button', 's1-button--color-' + block.color, ...this._tailwind.getSpacingClasses(block)];
    });

    sanitizedLink = computed(() => {
        return SbLinkHelper.sanitizeLink(this.block().link, this._sanitizer);
    });
}
